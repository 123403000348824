import * as Yup from 'yup';

export const yupLocale = (t: Function) => {
  Yup.setLocale({
    mixed: {
      default: t('mixed.default'),
      defined: t('mixed.defined'),
      notOneOf: t('mixed.notOneOf'),
      notType: t('mixed.notType'),
      oneOf: t('mixed.oneOf'),
      required: t('mixed.required'),
    },
    number: {
      min: t('number.min'),
      max: t('number.max'),
      lessThan: t('number.lessThan'),
      moreThan: t('number.moreThan'),
      positive: t('number.positive'),
      negative: t('number.negative'),
      integer: t('number.integer'),
    },
    string: {
      email: t('string.email'),
      length: t('string.length'),
      min: t('string.min'),
      max: t('string.max'),
      matches: t('string.matches'),
      url: t('string.url'),
      uuid: t('string.uuid'),
      trim: t('string.trim'),
      lowercase: t('string.lowercase'),
      uppercase: t('string.uppercase'),
    },
    boolean: {
      isValue: t('boolean.isValue'),
    },
    array: {
      min: t('array.min'),
      max: t('array.max'),
    },
  });
};

export const yupConfig = (t: Function) => {
  Yup.addMethod(Yup.string, 'email', args =>
    Yup.string().test('email-validate', args?.message, function (value) {
      const { path, createError } = this;
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return re.test(String(value).toLowerCase()) || createError({ message: t('string.email'), path });
    })
  );

  Yup.addMethod(Yup.number, 'number', args =>
    Yup.number().test('number-validate', args?.message, function (value: any) {
      const { path, createError } = this;
      const re = /^\d$/;

      return re.test(value) || createError({ message: t('string.must-be-number'), path });
    })
  );
};
