import React from 'react';
import { useController } from 'react-hook-form';
import { FormItemTextProps } from './component.d';

const FormItemText = ({
  name,
  control,
  defaultValue = undefined,
  children,
  onBlur,
  onChange,
  showError = true,
  TextField = true,
}: FormItemTextProps) => {
  const { field, fieldState } = useController({
    control,
    defaultValue,
    name,
  });
  const { ref, ...inputProps } = field;
  const { invalid, error } = fieldState;
  const errorProperties = showError
    ? {
        error: invalid,
        helperText: error?.message,
      }
    : {};
  const inputref = TextField
    ? {
        inputRef: ref,
      }
    : {};
  return React.cloneElement(children, {
    ...children.props,
    ...inputProps,
    onBlur: onBlur || inputProps.onBlur,
    onChange: onChange || inputProps.onChange,
    ...errorProperties,
    ...inputref,
  });
};

export default FormItemText;
