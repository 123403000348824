import FBLogo from 'assets/images/Facebook.png';
import TwitterLogo from 'assets/images/Twitter.png';
import InstaLogo from 'assets/images/Instagram.png';
import { Bold, ColumnContainer, FlextContainer } from 'components/Common/styledComponents';
import styled from 'styled-components';

const SocialMedia = () => {
  return (
    <Container>
      <FlextContainer as="div" style={{ gap: 14 }}>
        <a href="https://www.facebook.com/museokortti" target="_blank" rel="noreferrer">
          <img src={FBLogo} alt="FBlogo" width={35} />
        </a>
        <a href="https://www.instagram.com/museokortti/" target="_blank" rel="noreferrer">
          <img src={InstaLogo} alt="Instalogo" width={35} />
        </a>
        <a href="https://twitter.com/museokortti" target="_blank" rel="noreferrer">
          <img src={TwitterLogo} alt="Twitterlogo" width={35} />
        </a>
      </FlextContainer>
      <SocialMediaMessage>
        Seuraa Museokorttia Facebookissa, Instagramissa ja Twitterissä.
        <Bold>#museokortti #museokierros @museokortti</Bold>
      </SocialMediaMessage>
    </Container>
  );
};

export default SocialMedia;

const Container = styled(ColumnContainer)`
  gap: 10px;
  margin-top: 20px;
  padding: 0 40px;
`;

const SocialMediaMessage = styled.div`
  font-size: 14px !important;
`;
