import React, { memo, ReactElement } from 'react';
import { Helmet } from 'react-helmet';

type MetaProps = {
  children: ReactElement | ReactElement[];
};

const Meta = ({ children }: MetaProps) => {
  return <Helmet>{children}</Helmet>;
};

export default memo(Meta);
