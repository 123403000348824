export const BASE_NEW_URL = process.env.REACT_APP_BASE_URL ?? 'https://web.museo-staging.devolon.cloud';
export const ENV = process.env.REACT_APP_ENV ?? 'staging';
export const WebShopUrl =
  ENV === 'staging'
    ? 'http://museo-webshop-stage.s3-website.eu-central-1.amazonaws.com/'
    : 'https://osta.museokortti.fi/';
export const HeaderFooterSRC =
  process.env.REACT_APP_ENV === 'production'
    ? {
        header: `${BASE_NEW_URL}/web/web-pages/header`,
        footer: `${BASE_NEW_URL}/web/web-pages/footer`,
        headerStyle:
          'https://s3.eu-central-1.amazonaws.com/museo.production.storage/front_end_assets/public/css/style_header.css',
        footerStyle:
          'https://s3.eu-central-1.amazonaws.com/museo.production.storage/front_end_assets/public/css/style_footer.css',
        pageStyle:
          'https://s3.eu-central-1.amazonaws.com/museo.production.storage/front_end_assets/public/css/style_page.css',
        defer: 'https://s3.eu-central-1.amazonaws.com/museo.production.storage/front_end_assets/public/js/defer.js',
        mobiilinaviStyle:
          'https://s3.eu-central-1.amazonaws.com/museo.production.storage/front_end_assets/public/css/style_mobiilinavi.css',
        mobiilinavi:
          'https://s3.eu-central-1.amazonaws.com/museo.production.storage/front_end_assets/public/js/mobiilinavi.js',
      }
    : {
        header: `${BASE_NEW_URL}/web/web-pages/header`,
        footer: `${BASE_NEW_URL}/web/web-pages/footer`,
        headerStyle:
          'https://s3.eu-central-1.amazonaws.com/storage.museo.staging/front_end_assets/public/css/style_header.css',
        footerStyle:
          'https://s3.eu-central-1.amazonaws.com/storage.museo.staging/front_end_assets/public/css/style_footer.css',
        pageStyle:
          'https://s3.eu-central-1.amazonaws.com/storage.museo.staging/front_end_assets/public/css/style_page.css',
        defer: 'https://s3.eu-central-1.amazonaws.com/storage.museo.staging/front_end_assets/public/js/defer.js',
        mobiilinaviStyle:
          'https://s3.eu-central-1.amazonaws.com/storage.museo.staging/front_end_assets/public/css/style_mobiilinavi.css',
        mobiilinavi:
          'https://s3.eu-central-1.amazonaws.com/storage.museo.staging/front_end_assets/public/js/mobiilinavi.js',
      };
