export const htmlEntityDecode = (str: string): string => {
  if (typeof str === 'string') {
    str = str.replace('&nbsp;', ' ');
    str = str.replace(/\\n/g, '  ');
    str = str.replace(/<br>/g, '\n');
    str = str.replace(/&quot;/g, "''");
    str = str.replace(/&#228;/g, 'ä');
    str = str.replace(/&#226;/g, 'ö');
    str = str.replace(/&rdquo;/g, '"');
    str = str.replace(/&ndash;/g, '-');
    str = str.replace(//g, '€');
    str = str.replace(/&gt;/g, '>');
    str = str.replace('', '');
    return str.replace(/&#(\d+);/g, (match, dec) => {
      return String.fromCharCode(dec);
    });
  }
  return str;
};
