import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useForm from 'utils/hooks/use-form';
import { WebShopUrl } from 'constants/env';
import { useAppDispatch } from 'store/hooks';
import { Box, Container, RedirectLink } from 'components/Common/styledComponents';
import Logo from 'assets/images/companyOrderIcon.png';
import Intro from 'components/Main/intro';
import OrderAmount from 'components/Forms/orderAmount';
import { orderFormSchema } from 'orderSchema/orderFormSchema';
import { getCardPrice } from 'store/price/asyncActions';
import ConpanyInfo from 'components/Forms/companyInfo';
import DeliveryMethod from 'components/Forms/deliveryMethod';
import PersonalInfo from 'components/Forms/personalInfo';
import PaymentMethod from 'components/Forms/paymentMethod';
import BillingAddress from 'components/Forms/billingAddress';
import PartnerMuseum from 'components/Forms/partnerMuseum';
import { getMuseums } from 'store/museum/asyncActions';
import SubmitButton from 'components/submitButton';
import { submitOrder } from 'store/order/asyncActions';

const Main = () => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const { handleSubmit, formDebugger, ...formProps } = useForm({
    name: 'orderForm',
    schema: orderFormSchema,
  });

  useEffect(() => {
    dispatch(getCardPrice());
    dispatch(getMuseums());
  }, [dispatch]);

  const onSubmit = async (data: any) => {
    const { invoicingOptions, partner, paperInvoice, digiInvoice, ...rest } = data;
    const billingAddress = invoicingOptions === 'paper_bill' ? paperInvoice : digiInvoice;
    dispatch(submitOrder({ ...billingAddress, ...rest }));
  };

  return (
    <Container>
      <Box>
        <img src={Logo} alt="logo" width={45} />
        <Intro />
        <Form as="form" onSubmit={handleSubmit(onSubmit)}>
          <OrderAmount {...formProps} />
          <ConpanyInfo {...formProps} />
          <DeliveryMethod {...formProps} />
          <PersonalInfo {...formProps} />
          <PaymentMethod {...formProps} />
          <BillingAddress {...formProps} />
          <PartnerMuseum {...formProps} />
          <SubmitButton />
          <RedirectLink as="a" href={WebShopUrl} target="_blank">
            {t('back').toLocaleUpperCase()}
          </RedirectLink>
        </Form>
      </Box>
    </Container>
  );
};
export default Main;

const Form = styled.form`
  padding: 14px 28px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;
`;
