import { createSlice } from '@reduxjs/toolkit';
import { CompanyOrder } from 'api/main';
import { VariantType } from 'notistack';
import { RootState } from '../index';
import { orderPDF, submitOrder } from './asyncActions';

export interface Ordertate {
  is_fetching: boolean;
  data: CompanyOrder | null;
  status: VariantType | null;
  pdfUrl: string;
}

const initialState: Ordertate = {
  is_fetching: false,
  data: null,
  status: null,
  pdfUrl: '',
};

export const OrderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    deleteStatus: state => {
      state.status = null;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(submitOrder.pending, state => {
        state.is_fetching = true;
        state.status = null;
      })
      .addCase(submitOrder.fulfilled, (state, action) => {
        state.is_fetching = false;
        state.data = action.payload || null;
        state.status = 'success';
      })
      .addCase(submitOrder.rejected, state => {
        state.is_fetching = false;
        state.status = 'error';
      })
      .addCase(orderPDF.fulfilled, (state, action) => {
        state.pdfUrl = action.payload?.url || '';
      });
  },
});

export const { deleteStatus } = OrderSlice.actions;

export const selectIdentifier = (state: RootState): Ordertate['data'] => state.order.data;
export const selectIsFetching = (state: RootState): Ordertate['is_fetching'] => state.order.is_fetching;
export const selectStatus = (state: RootState): Ordertate['status'] => state.order.status;
export const selectPDFUrl = (state: RootState): Ordertate['pdfUrl'] => state.order.pdfUrl;

export default OrderSlice.reducer;
