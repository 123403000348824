import { createGlobalStyle } from 'styled-components';
import Montserrat from './Montserrat-Regular.ttf';
import MontserratBold from './Montserrat-Bold.ttf';
import MontserratLight from './Montserrat-ExtraLight.ttf';
import MontserratExtraBold from './Montserrat-ExtraBold.ttf';

export default createGlobalStyle`
    @font-face {
    font-family: 'Montserrat-Regular';
    src: url('${Montserrat}') format('truetype');
    }

    @font-face {
    font-family: 'Montserrat-bold';
    src: url('${MontserratBold}') format('truetype');
    }
    
    @font-face {
    font-family: 'Montserrat-extra-bold';
    src: url('${MontserratExtraBold}') format('truetype');
    }

    @font-face {
      font-family: 'Montserrat-light';
      src: url('${MontserratLight}') format('truetype');
      }
      
    html, body { 
        height: 100%; 
        width: 100%;
      }
`;
