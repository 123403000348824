import React from 'react';
import { useTranslation } from 'react-i18next';
import { Bold, RedirectLink, Title } from 'components/Common/styledComponents';
import { WebShopUrl } from '../../constants/env';

const Intro = () => {
  const [t] = useTranslation();

  return (
    <React.Fragment>
      <Title>{t('title')}</Title>
      <div>
        <Bold>{t('subTitle')}</Bold>
        <p>{t('description')}</p>
        <RedirectLink as="a" href="https://museot.fi/yrityksille" target="_blank">
          {t('readMoreRedirect')}
        </RedirectLink>
      </div>
      <div>
        <RedirectLink as="a" href="https://museot.fi/yritystilaus-ukk" target="_blank">
          {t('faqRedirect')}
        </RedirectLink>
        <Bold>
          {t('youCanBuyGift')}
          <RedirectLink as="a" target="_blank" href={WebShopUrl}>
            {t('fromOurOnline')}
          </RedirectLink>
        </Bold>
        <Bold>
          {t('takeContact')}
          <RedirectLink as="a" target="_blank" href="mailto: myynti@museot.fi">
            myynti@museot.fi
          </RedirectLink>{' '}
          044 4300 702
        </Bold>
      </div>
    </React.Fragment>
  );
};

export default Intro;
