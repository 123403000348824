import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { Bold, FieldsContainer, Input } from 'components/Common/styledComponents';
import FormItemText from 'utils/widgets/forms/form-item-text';
import { useAppSelector } from 'store/hooks';
import { selectMuseums } from 'store/museum';
import { MinimalMuseum } from 'api/main';
import styled from 'styled-components';

const PartnerMuseum = ({ control, setValue, clearErrors, formState }: any) => {
  const [t] = useTranslation();
  const museums = useAppSelector(selectMuseums);
  const [partner, setPartner] = useState<boolean>(false);
  let partnerIdError = formState.errors.partner_museum_id;

  return (
    <FieldsContainer>
      <Bold>{t('partnerMuseum')}</Bold>
      <div>{t('partnerMuseumDescription')}</div>
      <FormControlLabel
        control={<Checkbox checked={partner} />}
        label={t('addPartner')}
        onChange={() => {
          setValue('partner', !partner);
          setPartner(!partner);
        }}
      />

      {partner && (
        <FieldsContainer>
          {' '}
          <SelectRow>
            <Autocomplete
              options={museums?.data.filter((item: MinimalMuseum) => item.name) || []}
              className={partnerIdError ? 'select-field phone-error ' : 'select-field'}
              includeInputInList
              autoHighlight
              disablePortal
              noOptionsText={t('noOption')}
              onChange={(props, option) => {
                setValue('partner_museum_id', option?.id?.toString() || '');
                if (partnerIdError) {
                  clearErrors('partner_museum_id');
                }
                return option;
              }}
              getOptionLabel={(option: MinimalMuseum) => option.name || ''}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              )}
              renderInput={params => <TextField {...params} placeholder={`${t('partnerMuseum')}*`} />}
            />
            {partnerIdError && <p className="error-message">{partnerIdError.message}</p>}
          </SelectRow>
          <FormItemText defaultValue={''} control={control} name="partner_contact">
            <Input placeholder={`${t('partnerContactPerson')}*`} className="field" />
          </FormItemText>
        </FieldsContainer>
      )}
    </FieldsContainer>
  );
};

export default PartnerMuseum;

const SelectRow = styled.div`
  width: 100%;

  .phone-error {
    border: 2px solid #f44336 !important;
    background: #fff6f6 !important;
    input {
      color: red !important;
    }
  }
`;
