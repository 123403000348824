import { useTranslation } from 'react-i18next';
import FormItemText from 'utils/widgets/forms/form-item-text';
import ReactPhoneInput from 'react-phone-number-input';
import styled from 'styled-components';
import { FieldsContainer, Input, FlextContainer } from 'components/Common/styledComponents';
import { useEffect, useState } from 'react';

const PersonalInfo = ({
  control,
  watch,
  clearErrors,
  formState,
}: {
  control: any;
  watch: any;
  clearErrors: any;
  formState: any;
}) => {
  const [t] = useTranslation();
  const [required, setRequired] = useState<boolean>(true);
  let delType = watch('delivery_type');
  let phoneError = formState.errors.contact_phone;
  useEffect(() => {
    if (delType === 'digital') {
      setRequired(false);
      clearErrors('recipient');
      clearErrors('city');
      clearErrors('zip');
      clearErrors('address');
    } else setRequired(true);
  }, [delType, clearErrors]);

  return (
    <FieldsContainer>
      <FormItemText defaultValue={''} control={control} name="recipient">
        <Input placeholder={`${required ? t('recipient') + '*' : t('recipient')}`} className="field" />
      </FormItemText>
      <FormItemText defaultValue={''} control={control} name="address">
        <Input placeholder={`${required ? t('address') + '*' : t('address')}`} className="field" />
      </FormItemText>
      <FlexRow>
        <FormItemText defaultValue={''} control={control} name="zip">
          <ZipInput placeholder={`${required ? t('zip') + '*' : t('zip')}`} className="field" />
        </FormItemText>
        <FormItemText defaultValue={''} control={control} name="city">
          <CityInput placeholder={`${required ? t('city') + '*' : t('city')}`} className="field" />
        </FormItemText>
      </FlexRow>
      <FormItemText defaultValue={''} control={control} name="contact_person">
        <Input placeholder={t('contactName')} className="field" />
      </FormItemText>
      <FlexRow>
        <PhoneInputcontainer>
          <FormItemText defaultValue={''} control={control} name="contact_phone" showError={false} TextField={false}>
            <ReactPhoneInput
              placeholder={t('contactPhone')}
              defaultCountry="FI"
              onChange={e => null}
              className={phoneError ? 'phone-error' : ''}
              rules={{ required: true }}
            />
          </FormItemText>
          {formState.errors.contact_phone && <p className="error-message">{formState.errors.contact_phone.message}</p>}
        </PhoneInputcontainer>
        <FormItemText defaultValue={''} control={control} name="contact_email">
          <EqualInput placeholder={t('contactEmail')} className="field" />
        </FormItemText>
      </FlexRow>
      <div>{t('usedInCommunication')}</div>
    </FieldsContainer>
  );
};

export default PersonalInfo;

const FlexRow = styled(FlextContainer)`
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
`;

const ZipInput = styled(Input)`
  width: 35%;
`;
const CityInput = styled(Input)`
  width: 65%;
`;

const EqualInput = styled(Input)`
  width: 50%;
`;

const PhoneInputcontainer = styled.div`
  width: 50%;
  .PhoneInput {
    display: flex;
    align-items: center;
    border: 1px solid #fc3d7e;
    padding: 16.5px 14px;
    width: 100%;
  }
  .PhoneInput.phone-error {
    border: 2px solid #f44336 !important;
    background: #fff6f6 !important;
    input {
      color: red !important;
    }
  }
  .PhoneInputInput {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    font-family: Montserrat-Regular;
    border: 0;
    width: 100%;
    background: inherit;
  }

  .PhoneInputInput:focus {
    outline: 0;
  }

  .PhoneInputCountryIcon {
    width: calc(1em * 1.5);
    height: 1em;
  }

  .PhoneInputCountryIcon--square {
    width: 1em;
  }

  .PhoneInputCountryIcon--border {
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5), inset 0 0 0 1px rgba(0, 0, 0, 0.5);
  }

  .PhoneInputCountryIconImg {
    display: block;
    width: 100%;
    height: 100%;
  }

  .PhoneInputInternationalIconPhone {
    opacity: 0.8;
  }

  .PhoneInputInternationalIconGlobe {
    opacity: 0.65;
  }

  .PhoneInputCountry {
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    margin-right: 0.35em;
  }

  .PhoneInputCountrySelect {
    flex: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
  }

  .PhoneInputCountrySelect[disabled] {
    cursor: default;
  }

  .PhoneInputCountrySelectArrow {
    display: block;
    content: '';
    width: 0.3em;
    height: 0.3em;
    margin-left: 0.35em;
    border-style: solid;
    border-color: inherit;
    border-top-width: 0;
    border-bottom-width: 1px;
    border-left-width: 0;
    border-right-width: 1px;
    transform: rotate(45deg);
    opacity: 0.45;
  }

  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
    opacity: 1;
    color: #03b2cb;
  }

  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
    box-shadow: 0 0 0 1px #03b2cb, inset 0 0 0 1px #03b2cb;
  }

  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
    opacity: 1;
    color: #03b2cb;
  }
`;
