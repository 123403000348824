import React from 'react';
import { useTranslation } from 'react-i18next';
import { Bold, RedirectLink, Title } from 'components/Common/styledComponents';
import { useAppSelector } from 'store/hooks';
import { selectPDFUrl } from 'store/order';

const SuccessIntro = () => {
  const [t] = useTranslation();
  const pdfUrl = useAppSelector(selectPDFUrl);

  return (
    <React.Fragment>
      <Title>{t('successTitle')}</Title>
      <div style={{ padding: '0px 40px' }}>{t('successMessage')}</div>
      <div style={{ padding: '0px 40px' }}>
        {t('downloadPDF')}
        <RedirectLink as="a" target="_blank" href={pdfUrl}>
          {t('here')}
        </RedirectLink>
        .
      </div>
      <div>
        <Bold>{t('takeContact')}</Bold>
        myynti@museot.fi, puh. 044 4300 702
      </div>
    </React.Fragment>
  );
};

export default SuccessIntro;
