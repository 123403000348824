import styled from 'styled-components';
import { Box, Container } from 'components/Common/styledComponents';
import Logo from 'assets/images/companyOrderIcon.png';
import SuccessIntro from './successIntro';
import SocialMedia from './socialMedia';
import AdditionalInformations from './additionalInformations';

const Success = () => {
  return (
    <Container>
      <SuccessBox>
        <img src={Logo} alt="logo" width={45} />
        <SuccessIntro />
        <SocialMedia />
        <AdditionalInformations />
      </SuccessBox>
    </Container>
  );
};

export default Success;

const SuccessBox = styled(Box)`
  gap: 20px;
  padding: 0px;
  padding-bottom: 50px;
  padding-top: 15px;
`;
