import AppConfigs from 'app-configs';
import { BrowserRouter } from 'react-router-dom';
import { RouterProvider } from './providers';
import GlobalStyle from 'assets/fonts/fonts';
import Header from 'components/CustomComponents/Header';
import Footer from 'components/CustomComponents/Footer';

function App() {
  return (
    <BrowserRouter>
      <GlobalStyle />
      <Header />
      <div style={{ position: 'relative' }}>
        <AppConfigs>
          <RouterProvider />
        </AppConfigs>
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
