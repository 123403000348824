import * as Yup from 'yup';

export const orderFormSchema = Yup.object().shape(
  {
    amount: Yup.number().min(10).max(1000).required(),
    company: Yup.string().required('Tämä kenttä pitää täyttää'),
    company_on_card: Yup.string()
      .max(27)
      .test('validate-company-name', 'Teksti sisältää epäsopivia merkkejä', function (value) {
        var checkCompanyName = /^[ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ÷ØÙÜÚÞŠA-Z ,’0-9%#-]+$/u;
        return checkCompanyName.test(String(value).toUpperCase()) || value === '';
      }),
    delivery_type: Yup.string().default('letter'),
    recipient: Yup.string().when('delivery_type', {
      is: 'letter',
      then: Yup.string().required(),
      otherwise: Yup.string(),
    }),
    address: Yup.string().when('delivery_type', {
      is: 'letter',
      then: Yup.string().required(),
      otherwise: Yup.string(),
    }),
    zip: Yup.string().when('delivery_type', {
      is: 'letter',
      then: Yup.string().required(),
      otherwise: Yup.string(),
    }),
    city: Yup.string().when('delivery_type', {
      is: 'letter',
      then: Yup.string().required(),
      otherwise: Yup.string(),
    }),
    contact_person: Yup.string().required(),
    contact_phone: Yup.string().required(),
    contact_email: Yup.string().required(),
    invoicingOptions: Yup.string().default('paper_bill'),
    paperInvoice: Yup.object().when('invoicingOptions', {
      is: 'paper_bill',
      then: Yup.object().shape({
        invoicing_company: Yup.string().required(),
        business_code: Yup.string(),
        invoicing_address: Yup.string().required(),
        invoicing_zip: Yup.string().required(),
        invoicing_city: Yup.string().required(),
        invoice_reference: Yup.string().required(),
      }),
      otherwise: Yup.object().shape({
        invoicing_company: Yup.string(),
        business_code: Yup.string(),
        invoicing_address: Yup.string(),
        invoicing_zip: Yup.string(),
        invoicing_city: Yup.string(),
        invoice_reference: Yup.string(),
      }),
    }),
    digiInvoice: Yup.object().when('invoicingOptions', {
      is: 'digi_bill',
      then: Yup.object().shape({
        einvoice_address: Yup.string().required(),
        einvoice_operator: Yup.string().required(),
        einvoice_broker_id: Yup.string().required(),
      }),
      otherwise: Yup.object().shape({
        einvoice_address: Yup.string(),
        einvoice_operator: Yup.string(),
        einvoice_broker_id: Yup.string(),
      }),
    }),
    partner: Yup.boolean().default(false),
    partner_museum_id: Yup.string().when('partner', {
      is: true,
      then: Yup.string().required(),
      otherwise: Yup.string(),
    }),
    partner_contact: Yup.string().when('partner', {
      is: true,
      then: Yup.string().required(),
      otherwise: Yup.string(),
    }),
  },
  [
    ['delivery_type', 'recipient'],
    ['partner', 'partner_museum_id'],
  ]
);
