import React from 'react';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, SubmitErrorHandler, SubmitHandler, useForm as useReactHookForm } from 'react-hook-form';
import { UseFormProps, UseFormReturn } from './component.d';
import { ApiError } from '../../../api/main';

const useForm = ({ name, schema, ...configs }: UseFormProps): UseFormReturn => {
  const { handleSubmit: handleSubmitForm, ...methods } = useReactHookForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    shouldFocusError: true,
    ...configs,
  });

  const { setError, reset: resetFormMethod } = methods;

  const resetForm = (data: any) => {
    try {
      for (const key in data) {
        if (typeof data[key] === 'boolean') {
          data[key] = false;
        } else if (Array.isArray(data[key])) {
          data[key] = [];
        } else {
          data[key] = undefined;
        }
      }

      resetFormMethod({ ...data });
    } catch (e) {
      return null;
    }
  };

  const handleError = (onError: SubmitErrorHandler<FieldValues>) => (error: any) => {
    onError(error);
    return error;
  };

  const formDebugger = () => <DevTool control={methods.control} />;

  const handleSubmission =
    (
      submit: SubmitHandler<FieldValues> = (values: object) => Promise.resolve(values),
      reset = false,
      onError = (e: any) => e
    ): SubmitHandler<FieldValues> =>
    data => {
      try {
        submit(data)
          .then((formResponse: object) => {
            if (reset) {
              resetForm(data);
            }
            return Promise.resolve(formResponse);
          })
          .catch((e: ApiError) => {
            if (Array.isArray(e?.message)) {
              Object.entries(e.message).forEach((fieldError: any) => {
                fieldError[1].forEach((errorMessage: any) => {
                  setError(fieldError[0], {
                    message: errorMessage,
                    type: 'server',
                  });
                });
              });
            }
            onError(e);
            return Promise.reject(e);
          });
      } catch (e) {
        onError(e);
      }
    };

  return {
    formDebugger,
    handleSubmit: (onSubmit: SubmitHandler<FieldValues>, reset = false, onError = (e: any) => e) =>
      handleSubmitForm(handleSubmission(onSubmit, reset, onError), handleError(onError)),
    ...methods,
  };
};

export default useForm;
