import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Bold, FieldsContainer } from 'components/Common/styledComponents';
import { useTranslation } from 'react-i18next';
import FormItemText from 'utils/widgets/forms/form-item-text';

const PaymentMethod = ({ control }: any) => {
  const [t] = useTranslation();
  return (
    <FieldsContainer>
      <Bold>{t('choosePaymentMethod')}</Bold>
      <FormItemText
        defaultValue={'paper_bill'}
        control={control}
        name="invoicingOptions"
        showError={false}
        TextField={false}
      >
        <RadioGroup name="invoicingOptions" className="row-radio-field">
          <FormControlLabel value="paper_bill" control={<Radio size="small" />} label={t('paperBill')} />
          <FormControlLabel value="digi_bill" control={<Radio />} label={t('onlineInvoice')} />
        </RadioGroup>
      </FormItemText>
    </FieldsContainer>
  );
};

export default PaymentMethod;
