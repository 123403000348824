export const dark = {
  palette: {
    mode: 'dark',
    customGray: {
      main: '#ccc',
    },
    primary: {
      main: '#AC4FC6',
    },
    secondary: {
      main: '#20B8CE',
    },
  },
};
