import { useRoutes } from 'react-router-dom';
import Main from 'components/Main';
import Success from 'components/success';

export const RouterProvider = () =>
  useRoutes([
    {
      path: '*',
      element: <Main />,
    },
    {
      path: '/success',
      element: <Success />,
    },
  ]);

export default RouterProvider;
