import React from 'react';
import { useEffect, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import colors from '../../constants/colors';
import { HeaderFooterSRC } from '../../constants/env';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { htmlEntityDecode } from '../../utils';

export default function Header() {
  const [header, setHeader] = useState('');
  const [, { language }] = useTranslation();

  useEffect(() => {
    const lang = localStorage.getItem('i18nextLng') || 'fi';

    fetch(`${HeaderFooterSRC.header}?lang=${lang}`)
      .then(function (response) {
        return response.text();
      })
      .then(function (data) {
        const head = document.head;

        const headerStyleJs = document.createElement('script');
        headerStyleJs.type = 'text/javascript';
        headerStyleJs.src = HeaderFooterSRC.defer;
        headerStyleJs.async = true;
        document.body.appendChild(headerStyleJs);

        const headerStylemobi = document.createElement('script');
        headerStylemobi.type = 'text/javascript';
        headerStylemobi.src = HeaderFooterSRC.mobiilinavi;
        headerStyleJs.async = true;
        document.body.appendChild(headerStylemobi);

        const pageStyleLink = document.createElement('link');
        pageStyleLink.type = 'text/css';
        pageStyleLink.rel = 'stylesheet';
        pageStyleLink.href = HeaderFooterSRC.pageStyle;
        head.appendChild(pageStyleLink);

        const headerStyleLink = document.createElement('link');
        headerStyleLink.type = 'text/css';
        headerStyleLink.rel = 'stylesheet';
        headerStyleLink.href = HeaderFooterSRC.headerStyle;
        head.appendChild(headerStyleLink);

        const mobiilinaviCss = document.createElement('link');
        mobiilinaviCss.type = 'text/css';
        mobiilinaviCss.rel = 'stylesheet';
        mobiilinaviCss.href = HeaderFooterSRC.mobiilinaviStyle;
        head.appendChild(mobiilinaviCss);

        setHeader(data);
      });
  }, [language]);

  return header ? (
    <Container>
      <div dangerouslySetInnerHTML={{ __html: htmlEntityDecode(header) }}></div>
    </Container>
  ) : (
    <ClipLoader size={5} color={colors.primary} />
  );
}

const Container = styled.div`
  @media print {
    display: none;
  }
  .header-wide-stripe {
    background-color: #fff572;
    text-align: center;
    font-size: 1rem;
    padding: 1em;
    color: #2c253d;
    font-family: RightGrotesk-Wide;
    font-weight: bold;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.12px;
  }
  .lue_lisaa {
    display: inline-block;
    padding: 0 18px 0 0;
    background-image: url(https://museot.fi/kuvat/icon/icon-arrow-small-16.svg);
    background-repeat: no-repeat;
    background-position: right 3px;
    font-weight: bold;
    color: #f94b84;
  }
`;
