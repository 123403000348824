import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { WebService } from 'api/main';
import { orderRequestBody } from './type';

export const submitOrder: AsyncThunk<any, orderRequestBody, {}> = createAsyncThunk(
  'submit/order',
  async (body: orderRequestBody, { rejectWithValue, dispatch }) => {
    try {
      const res = await WebService.webCompanyOrder(body);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      if (res?.identifier) dispatch(orderPDF(res.identifier || ''));
      return res;
    } catch (e) {
      return rejectWithValue({ identifier: '' });
    }
  }
);

export const orderPDF: AsyncThunk<
  | {
      url?: string;
    }
  | undefined,
  string,
  {}
> = createAsyncThunk('order/pdf', async (body: string) => {
  try {
    const res = await WebService.webCompanyOrderPdf(body);
    return res;
  } catch (e) {
    return undefined;
  }
});
