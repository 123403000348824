import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Bold, FieldsContainer } from 'components/Common/styledComponents';
import { useTranslation } from 'react-i18next';
import FormItemText from 'utils/widgets/forms/form-item-text';

const DeliveryMethod = ({ control }: any) => {
  const [t] = useTranslation();
  return (
    <FieldsContainer>
      <Bold>{t('chooseDeliveryMethod')}</Bold>
      <FormItemText defaultValue={'letter'} control={control} name="delivery_type" showError={false} TextField={false}>
        <RadioGroup name="delivery_type" className="radio-field">
          <FormControlLabel
            value="letter"
            control={<Radio />}
            label={
              <div>
                <strong>{t('byPost')}</strong>
                {t('letterMethod')}
              </div>
            }
          />
          <FormControlLabel
            value="digital"
            control={<Radio />}
            label={
              <div>
                <strong>{t('digitalDelivery')}</strong>
                {t('digitalMethod')}
              </div>
            }
          />
        </RadioGroup>
      </FormItemText>
    </FieldsContainer>
  );
};

export default DeliveryMethod;
