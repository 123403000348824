import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { getCardPrice } from './asyncActions';

export interface CarPriceState {
  is_fetching: boolean;
  data: any;
  status: string | null;
}

const initialState: CarPriceState = {
  is_fetching: false,
  data: {
    cents: 0,
    type: '',
    started_at: '',
  },
  status: null,
};

export const CarPriceSlice = createSlice({
  name: 'cardPrice',
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
    deleteStatus: state => {
      state.status = null;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getCardPrice.pending, state => {
        state.is_fetching = true;
      })
      .addCase(getCardPrice.fulfilled, (state, action) => {
        state.is_fetching = false;
        state.data = action.payload || initialState.data;
      })
      .addCase(getCardPrice.rejected, state => {
        state.is_fetching = false;
      });
  },
});

export const { setStatus, deleteStatus } = CarPriceSlice.actions;

export const selectCardPrice = (state: RootState): CarPriceState['data'] => state.cardPrice.data;

export default CarPriceSlice.reducer;
