import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import carPriceReducer from './price';
import museumsReducer from './museum';
import orderReducer from './order';

export const store = configureStore({
  reducer: {
    cardPrice: carPriceReducer,
    museums: museumsReducer,
    order: orderReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
