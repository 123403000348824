import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { MinimalMuseum } from 'api/main';
import { RootState } from '../index';
import { getMuseums } from './asyncActions';

export interface MuseumState {
  is_fetching: boolean;
  data: any | null;
  status: string | null;
}

const initialState: MuseumState = {
  is_fetching: false,
  data: null,
  status: null,
};

export const Museumlice = createSlice({
  name: 'museums',
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
    deleteStatus: state => {
      state.status = null;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getMuseums.pending, state => {
        state.is_fetching = true;
      })
      .addCase(getMuseums.fulfilled, (state, action) => {
        state.is_fetching = false;
        state.data = action.payload;
      })
      .addCase(getMuseums.rejected, state => {
        state.is_fetching = false;
      });
  },
});

export const { setStatus, deleteStatus } = Museumlice.actions;

export const selectMuseums = (state: RootState): MuseumState['data'] => state.museums.data;

export default Museumlice.reducer;
