import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import styled from 'styled-components';
import FormItemText from 'utils/widgets/forms/form-item-text/component';
import { Bold, FlextContainer } from 'components/Common/styledComponents';
import colors from 'constants/colors';
import { useAppSelector } from 'store/hooks';
import { selectCardPrice } from 'store/price';

const OrderAmount = ({ control, getValues, setValue }: { control: any; getValues: any; setValue: any }) => {
  const [t] = useTranslation();
  const { cents } = useAppSelector(selectCardPrice);
  const [price, setPrice] = useState<number>(0);

  const calculatePrice = useCallback(() => {
    let value = getValues('amount');
    setPrice(value * (cents / 100 || 0));
  }, [cents, getValues]);

  useEffect(() => {
    calculatePrice();
  }, [cents, calculatePrice]);

  const increment = () => {
    let value = getValues('amount');
    value < 1000 && setValue('amount', +value + 1);
    calculatePrice();
  };

  const decrement = () => {
    let value = getValues('amount');
    value > 10 && setValue('amount', +value - 1);
    calculatePrice();
  };

  const onChange = (e: any) => {
    let value;
    if (e.target.value < 10) value = 10;
    else if (e.target.value > 1000) value = 1000;
    else value = e.target.value;
    setValue('amount', value);
    calculatePrice();
  };

  return (
    <Container>
      <BoldContainer>{t('selectQTY')}</BoldContainer>
      <InputContainer>
        <RemoveCircleIcon htmlColor={colors.primary} fontSize="small" onClick={decrement} />
        <FormItemText
          defaultValue={10}
          control={control}
          name="amount"
          onBlur={onChange}
          showError={false}
          TextField={false}
        >
          <Input as="input" className="field" type="number" name="amount" min={10} max={1000}></Input>
        </FormItemText>
        <AddCircleIcon htmlColor={colors.primary} fontSize="small" onClick={increment} />
      </InputContainer>
      <BoldContainer>{t('total', { price: price })}</BoldContainer>
    </Container>
  );
};

export default OrderAmount;

const Container = styled(FlextContainer)`
  justify-content: space-between;
  width: 100%;
`;

const Input = styled.input`
  text-align: center;
  width: 6rem;
  border: 1px solid #fc3d7e !important;
`;

const InputContainer = styled(FlextContainer)`
  justify-content: space-between;
  gap: 8px;
  svg {
    height: 18px;
    cursor: pointer;
  }
`;

const BoldContainer = styled(Bold)`
  width: 25% !important;
`;
