import React from 'react';
import { useTranslation } from 'react-i18next';
import { Bold, ColumnContainer, RedirectLink } from 'components/Common/styledComponents';
import styled from 'styled-components';

const AdditionalInformations = () => {
  const [t] = useTranslation();

  return (
    <React.Fragment>
      <InfoWrapper>
        <Bold>{t('cardDestinationTitle')}</Bold>
        <div>{t('cardDestinationDescription')}</div>
        <RedirectLink as="a" href="https://museot.fi/museohaku" target="_blank">
          {t('cardDestinationList')} &gt;
        </RedirectLink>
        <RedirectLink
          as="a"
          href="https://museot.fi/museohaku/index.php?tulosta=1&amp;paluu=%2Fmuseohaku%2Findex.php"
          target="_blank"
        >
          {t('printTargetList')} &gt;
        </RedirectLink>
      </InfoWrapper>
      <InfoWrapper>
        <Bold>{t('browseTitle')}</Bold>
        <div>{t('browseDescription')}</div>
        <RedirectLink as="a" href="https://museot.fi/nayttelykalenteri/" target="_blank">
          {t('exhibitionsList')} &gt;
        </RedirectLink>
        <RedirectLink as="a" href="https://museot.fi/tapahtumat/" target="_blank">
          {t('eventsList')} &gt;
        </RedirectLink>
      </InfoWrapper>
      <InfoWrapper>
        <Bold>{t('findMuseumTitle')}</Bold>
        <div>{t('findMuseumDescription')}</div>
        <RedirectLink as="a" href="https://museot.fi/kulttuurikierros-kartta/karttahaku.php" target="_blank">
          {t('mapSearch')} &gt;
        </RedirectLink>
      </InfoWrapper>
      <InfoWrapper>
        <Bold>{t('answersToQuestions')}</Bold>
        <RedirectLink as="a" href="https://museot.fi/vastauksia" target="_blank">
          {t('faq')} &gt;
        </RedirectLink>
      </InfoWrapper>
    </React.Fragment>
  );
};

export default AdditionalInformations;

const InfoWrapper = styled(ColumnContainer)`
  padding: 0px 40px;
  align-items: start;
  width: 100%;
  & * {
    text-align: left;
  }
`;
