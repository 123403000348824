import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FieldsContainer, Input, FlextContainer, Bold } from 'components/Common/styledComponents';
import FormItemText from 'utils/widgets/forms/form-item-text';
import React, { useEffect } from 'react';

const BillingAddress = ({ control, watch, clearErrors }: any) => {
  const [t] = useTranslation();
  let paymentMethod = watch('invoicingOptions');

  useEffect(() => {
    clearErrors();
  }, [paymentMethod, clearErrors]);

  return (
    <FieldsContainer>
      {paymentMethod === 'paper_bill' ? (
        <React.Fragment>
          <Bold>{t('billingAddress')}:</Bold>
          <FlexRow>
            <FormItemText defaultValue={''} control={control} name="paperInvoice.invoicing_company">
              <SmallInput placeholder={t('companyName')} className="field" />
            </FormItemText>
            <FormItemText defaultValue={''} control={control} name="paperInvoice.business_code">
              <LargeInput placeholder={t('yID')} className="field" />
            </FormItemText>
          </FlexRow>
          <FormItemText defaultValue={''} control={control} name="paperInvoice.invoicing_address">
            <Input placeholder={`${t('billingAddress')}*`} className="field" />
          </FormItemText>
          <FlexRow>
            <FormItemText defaultValue={''} control={control} name="paperInvoice.invoicing_zip">
              <LargeInput placeholder={`${t('zip')}*`} className="field" />
            </FormItemText>
            <FormItemText defaultValue={''} control={control} name="paperInvoice.invoicing_city">
              <Input placeholder={`${t('city')}*`} className="field" />
            </FormItemText>
          </FlexRow>
          <FormItemText defaultValue={''} control={control} name="paperInvoice.invoice_reference">
            <Input placeholder={`${t('referenceInfo')}*`} className="field" />
          </FormItemText>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Bold>{t('onlineBillingAddress')}:</Bold>
          <FlexRow>
            <FormItemText defaultValue={''} control={control} name="digiInvoice.einvoice_address">
              <LargeInput placeholder={`${t('onlineBillingAddress')}*`} className="field" />
            </FormItemText>
            <FormItemText defaultValue={''} control={control} name="digiInvoice.einvoice_operator">
              <SmallInput placeholder={t('operator')} className="field" />
            </FormItemText>
          </FlexRow>
          <FormItemText defaultValue={''} control={control} name="digiInvoice.einvoice_broker_id">
            <Input placeholder={t('brokerID')} className="field" />
          </FormItemText>
        </React.Fragment>
      )}
    </FieldsContainer>
  );
};

export default BillingAddress;

const FlexRow = styled(FlextContainer)`
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
`;

const SmallInput = styled(Input)`
  width: 35%;
`;
const LargeInput = styled(Input)`
  width: 65%;
`;
