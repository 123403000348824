import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import colors from 'constants/colors';
import { useAppSelector } from 'store/hooks';
import { deleteStatus, selectIsFetching, selectStatus } from 'store/order';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';

const SubmitButton = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const isFetching = useAppSelector(selectIsFetching);
  const status = useAppSelector(selectStatus);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (status && status === 'success') {
      navigate('success');
      deleteStatus();
    }
  }, [status, enqueueSnackbar, t, navigate]);

  return (
    <Button as="button" type="submit" disabled={isFetching}>
      {isFetching ? <CircularProgress color={'inherit'} size={20} /> : t('send').toLocaleUpperCase()}
    </Button>
  );
};

export default SubmitButton;

const Button = styled.button`
  background-color: #ff598f;
  color: ${colors.white};
  border-radius: 0;
  height: 56px;
  width: 100%;
  font-weight: 700;
  &:hover {
    // box-shadow: rgba(44, 37, 61, 0.2) 0px 24px 24px -16px;
    background-color: rgb(255, 64, 126);
  }
  &:disabled {
    opacity: 0.7;
    &:hover {
      background-color: #ff598f;
    }
  }
`;
