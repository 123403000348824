import { createContext } from 'react';

type InitDataType = {
  mode: string;
  setMode: Function;
};

const initData = {
  mode: 'light',
  setMode: () => null,
};

export const StyleProviderContext = createContext(initData as InitDataType);
