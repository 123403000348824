import { useTranslation } from 'react-i18next';
import { Bold, FieldsContainer, Input } from 'components/Common/styledComponents';
import FormItemText from 'utils/widgets/forms/form-item-text';

const ConpanyInfo = ({ control }: any) => {
  const [t] = useTranslation();

  return (
    <FieldsContainer>
      <Bold>{t('companyInfo')}</Bold>
      <FormItemText defaultValue={''} control={control} name="company">
        <Input placeholder={t('organizationName')} className="field" />
      </FormItemText>
      <FormItemText defaultValue={''} control={control} name="company_on_card">
        <Input placeholder={t('textOnCard')} className="field" />
      </FormItemText>
      <div>{t('youCanAddText')}</div>
    </FieldsContainer>
  );
};

export default ConpanyInfo;
