import { TextField } from '@mui/material';
import colors from 'constants/colors';
import styled from 'styled-components';

export const RedirectLink = styled.a`
  color: ${colors.primary};
  font-weight: bold;
`;

export const Bold = styled.div`
  font-weight: bold;
`;

export const FlextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ColumnContainer = styled(FlextContainer)`
  flex-direction: column;
`;

export const FieldsContainer = styled(ColumnContainer)`
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  text-align: left;
`;

export const Input = styled(TextField)`
  width: 100%;
`;

export const Container = styled(FlextContainer)`
  width: 100%;
  height: 100%;
  padding: 14px;
  background-color: ${colors.inactive};
`;

export const Box = styled(ColumnContainer)`
  background-color: ${colors.white};
  width: 536px;
  text-align: center;
  padding: 14px;
  font-size: 15px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: normal;
  white-space: pre-line;
  gap: 14px;

  & * {
    letter-spacing: normal !important;
    line-height: 20px !important;
    font-size: 15px !important;
    font-family: Montserrat-Regular !important;
  }
`;

export const Title = styled.h1`
  font-size: 22pt !important;
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: initial !important;
`;
